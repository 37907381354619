export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error: any, instance, info) => {
    console.error(error) // Send logs to your error tracking service

    ElMessage({
      message: "An error occurred, please try again later",
      type: "error",
      grouping: true
    })
  }
})
