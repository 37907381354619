import UserInfo from '~/store/user'
import { getCookieData } from '~/utils/storage'
import { INVITE_APP } from '~/constants/user'
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { userInfo, isGetUserData, isFirtRouterHome, refId } = storeToRefs(UserInfo())
  const accessToken = getCookieData('access_token')
  const excludedRoutes = ['/']
  refId.value = to.query?.[INVITE_APP] as any
  // const queryMode = to.query?.mode as any
  if (accessToken && !isGetUserData.value && !excludedRoutes.includes(to.path)) {
    await UserInfo().getUserInfo()
    await UserInfo().getDataHome()
    isFirtRouterHome.value = false
  }

  // UserInfo().setQueryData(refId, queryMode)
  if (!excludedRoutes.includes(to.path) && !accessToken) {
    return navigateTo('/')
  }
})
