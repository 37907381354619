import axios from 'axios'
import { ACCESS_TOKEN } from '~/constants/wallet'
import UserInfo from '~/store/user'
import { getCookieData, deleteCookieData } from '~/utils/storage.ts'

export function request() {
  const apiBaseUrl = useRuntimeConfig().public.apiBase
  let headersParams = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
  const accessToken = getCookieData(ACCESS_TOKEN)
  if (accessToken) {
    headersParams = {
      ...headersParams,
      Authorization: 'Bearer ' + accessToken
    }
  }
  const instance = axios.create({
    baseURL: apiBaseUrl,
    headers: headersParams
  })

  instance.interceptors.response.use(
    async (response) => {
      if (response.data.status_code === 401) {
        localStorage.removeItem('siwe_session_key')
        deleteCookieData(ACCESS_TOKEN)
        window.location.href = '/'
      }
      return response
    },
    (error) => {
      throw error
    }
  )
  return instance
}

export async function getRequest(url, params) {
  try {
    return await request().get(url, { params: { ...params } })
  } catch (e) {
    console.log(e)
  }
}
export async function postRequest(url, body) {
  try {
    return await request().post(url, body)
  } catch (e) {
    console.log(e)
  }
}
