<template>
  <div class="bg_d_app">
    <div class="bg_d_app fixed inset-0"></div>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
  </div>
</template>
<style scoped lang="scss">
.bg_d_app{
  background-image: url(./assets/images/bg_app.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}
</style>