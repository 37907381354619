import Cookies from 'js-cookie'

export const setCookieData = (key: any, value: any) => {
  Cookies.set(key, value)
}

export const deleteCookieData = (key: any) => {
  Cookies.remove(key)
}

export const getCookieData = (key: any) => {
  return Cookies.get(key) || ''
}

export function removeStorage(key: any) {
  return Cookies.remove(key)
}

export const setStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const deleteStorage = (key: string): void => {
  localStorage.removeItem(key)
}

export const getStorage = (key: string): string => {
  return localStorage.getItem(key) || ''
}
