import { default as checking_45accounteHjv5ml7UMMeta } from "D:/laragon/www/gumart-dapp/pages/checking-account.vue?macro=true";
import { default as homeBSKzFpi3wUMeta } from "D:/laragon/www/gumart-dapp/pages/home.vue?macro=true";
import { default as indexYuUABXBCVeMeta } from "D:/laragon/www/gumart-dapp/pages/index.vue?macro=true";
import { default as missionXW1C4CcNtXMeta } from "D:/laragon/www/gumart-dapp/pages/mission.vue?macro=true";
import { default as not_45data3pIpZL1zXSMeta } from "D:/laragon/www/gumart-dapp/pages/not-data.vue?macro=true";
import { default as onboardg6btLSLJNDMeta } from "D:/laragon/www/gumart-dapp/pages/onboard.vue?macro=true";
import { default as partnersrRuyiiiOu9Meta } from "D:/laragon/www/gumart-dapp/pages/partners.vue?macro=true";
import { default as shopp4oA8mA9qyMeta } from "D:/laragon/www/gumart-dapp/pages/shop.vue?macro=true";
import { default as tierXiUvhrwPhPMeta } from "D:/laragon/www/gumart-dapp/pages/tier.vue?macro=true";
import { default as total_45gumohKGPLVmrOMeta } from "D:/laragon/www/gumart-dapp/pages/total-gum.vue?macro=true";
import { default as walletzYFmkzLQgJMeta } from "D:/laragon/www/gumart-dapp/pages/wallet.vue?macro=true";
import { default as welcome8L1KqemH3WMeta } from "D:/laragon/www/gumart-dapp/pages/welcome.vue?macro=true";
export default [
  {
    name: checking_45accounteHjv5ml7UMMeta?.name ?? "checking-account",
    path: checking_45accounteHjv5ml7UMMeta?.path ?? "/checking-account",
    meta: checking_45accounteHjv5ml7UMMeta || {},
    alias: checking_45accounteHjv5ml7UMMeta?.alias || [],
    redirect: checking_45accounteHjv5ml7UMMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/checking-account.vue").then(m => m.default || m)
  },
  {
    name: homeBSKzFpi3wUMeta?.name ?? "home",
    path: homeBSKzFpi3wUMeta?.path ?? "/home",
    meta: homeBSKzFpi3wUMeta || {},
    alias: homeBSKzFpi3wUMeta?.alias || [],
    redirect: homeBSKzFpi3wUMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexYuUABXBCVeMeta?.name ?? "index",
    path: indexYuUABXBCVeMeta?.path ?? "/",
    meta: indexYuUABXBCVeMeta || {},
    alias: indexYuUABXBCVeMeta?.alias || [],
    redirect: indexYuUABXBCVeMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: missionXW1C4CcNtXMeta?.name ?? "mission",
    path: missionXW1C4CcNtXMeta?.path ?? "/mission",
    meta: missionXW1C4CcNtXMeta || {},
    alias: missionXW1C4CcNtXMeta?.alias || [],
    redirect: missionXW1C4CcNtXMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: not_45data3pIpZL1zXSMeta?.name ?? "not-data",
    path: not_45data3pIpZL1zXSMeta?.path ?? "/not-data",
    meta: not_45data3pIpZL1zXSMeta || {},
    alias: not_45data3pIpZL1zXSMeta?.alias || [],
    redirect: not_45data3pIpZL1zXSMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/not-data.vue").then(m => m.default || m)
  },
  {
    name: onboardg6btLSLJNDMeta?.name ?? "onboard",
    path: onboardg6btLSLJNDMeta?.path ?? "/onboard",
    meta: onboardg6btLSLJNDMeta || {},
    alias: onboardg6btLSLJNDMeta?.alias || [],
    redirect: onboardg6btLSLJNDMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/onboard.vue").then(m => m.default || m)
  },
  {
    name: partnersrRuyiiiOu9Meta?.name ?? "partners",
    path: partnersrRuyiiiOu9Meta?.path ?? "/partners",
    meta: partnersrRuyiiiOu9Meta || {},
    alias: partnersrRuyiiiOu9Meta?.alias || [],
    redirect: partnersrRuyiiiOu9Meta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: shopp4oA8mA9qyMeta?.name ?? "shop",
    path: shopp4oA8mA9qyMeta?.path ?? "/shop",
    meta: shopp4oA8mA9qyMeta || {},
    alias: shopp4oA8mA9qyMeta?.alias || [],
    redirect: shopp4oA8mA9qyMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: tierXiUvhrwPhPMeta?.name ?? "tier",
    path: tierXiUvhrwPhPMeta?.path ?? "/tier",
    meta: tierXiUvhrwPhPMeta || {},
    alias: tierXiUvhrwPhPMeta?.alias || [],
    redirect: tierXiUvhrwPhPMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/tier.vue").then(m => m.default || m)
  },
  {
    name: total_45gumohKGPLVmrOMeta?.name ?? "total-gum",
    path: total_45gumohKGPLVmrOMeta?.path ?? "/total-gum",
    meta: total_45gumohKGPLVmrOMeta || {},
    alias: total_45gumohKGPLVmrOMeta?.alias || [],
    redirect: total_45gumohKGPLVmrOMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/total-gum.vue").then(m => m.default || m)
  },
  {
    name: walletzYFmkzLQgJMeta?.name ?? "wallet",
    path: walletzYFmkzLQgJMeta?.path ?? "/wallet",
    meta: walletzYFmkzLQgJMeta || {},
    alias: walletzYFmkzLQgJMeta?.alias || [],
    redirect: walletzYFmkzLQgJMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/wallet.vue").then(m => m.default || m)
  },
  {
    name: welcome8L1KqemH3WMeta?.name ?? "welcome",
    path: welcome8L1KqemH3WMeta?.path ?? "/welcome",
    meta: welcome8L1KqemH3WMeta || {},
    alias: welcome8L1KqemH3WMeta?.alias || [],
    redirect: welcome8L1KqemH3WMeta?.redirect,
    component: () => import("D:/laragon/www/gumart-dapp/pages/welcome.vue").then(m => m.default || m)
  }
]