import revive_payload_client_4sVQNw7RlN from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/laragon/www/gumart-dapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/laragon/www/gumart-dapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "D:/laragon/www/gumart-dapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_PZVGm6LH3G from "D:/laragon/www/gumart-dapp/plugins/sentry.js";
import error_handler_kEP5FliEXj from "D:/laragon/www/gumart-dapp/plugins/error-handler.ts";
import buffer_uHmzFicKEx from "D:/laragon/www/gumart-dapp/plugins/buffer.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  sentry_PZVGm6LH3G,
  error_handler_kEP5FliEXj,
  buffer_uHmzFicKEx
]